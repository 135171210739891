import React from 'react';
import { Link, graphql } from 'gatsby';
import ReactMarkdown from "react-markdown"
import Social from "../components/social.js"
import { FaArrowAltCircleLeft } from "react-icons/fa"
import Layout from "../components/LayoutAlt.js"

export default function WorldSinglePage( { data }) {
    const world = data.strapiWorldtodays

    return(
        <Layout>
            <div className="sm:w-3/4 sm:ml-1/8 xs:mt-14 sm:mt-10 xl:mt-20 xs:p-4 sm:p-0 mb-20">
            
             <h1 className="font-playfairdisplay font-bold text-blue-dark xs:text-3xl sm:text-4xl lg:text-5xl">{world.title}</h1>

             <p className="font-opensans text-sm text-gray-700 mt-4 w-11/12 md:w-3/4 xl:w-2/4">{world.b_desc}</p>

             <div className="flex justify-left items-center space-x-2 mt-4">
                        <img src={world.author.avatar.formats.thumbnail.url} alt="Author's Avatar" className="xs:h-8 xs:w-8 lg:h-10 lg:w-10 rounded-full border-2"/>
                        <p className="font-opensans font-bold xs:text-xs xl:text-sm">{world.author.name},</p>
                        <p className="font-opensans xs:text-xs xl:text-sm">{world.date}.</p>
                    </div>


            <Link to="/worldtoday">
                <section className="font-opensans  text-white  bg-orange-dark xs:mt-12 sm:mt-20 lg:mt-5 xl:mt-10 py-2 pl-4 
                  rounded-r-xl flex items-center">
                    <FaArrowAltCircleLeft size="20" className="mr-2 cursor-pointer"/> 
                    <p className=" text-xs sm:text-base">/worldtoday/{world.slug}</p>
                  </section>
            </Link>
            
            <section className="w-4/5 ml-1/10 mt-10">
                <div>
                    <img src={world.image.url} alt="World Report" className="w-full h-96 object-cover border-8 border-gray-200" />
                </div>
                <div className="articles font-light sm:text-lg sm:text-justify tracking-wide text-gray-700">
                    <ReactMarkdown children={world.content} />
                </div>
            </section>

            <section className="mt-20">
               <Link to="/worldtoday">
                    <button className="font-opensans font-bold text-white bg-orange-dark ring ring-orange-light ring-offset-2 rounded-sm py-1 px-8">
                     Back
                    </button>
                    </Link>
               </section>

            </div>
            <Social />
        </Layout>
    )
}

export const query = graphql`
query($slug: String!){
    strapiWorldtodays( slug: {eq: $slug} ) {
      title
      slug
      image {
            url
      }
      id
      date(formatString: "LL")
      content
      b_desc
      author {
        name
        slug
        avatar {
          formats {
            thumbnail {
              url
            }
          }
        }
      }
    }
  }
`
